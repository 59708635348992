// lichessExplorer.js

class LichessExplorer {
    constructor(options = {}) {
        this.baseUrl = options.baseUrl || 'https://explorer.lichess.ovh/lichess';
        this.variant = options.variant || 'standard';
        this.speeds = options.speeds || ['blitz', 'rapid', 'classical'];
        this.ratings = options.ratings || [1200, 1400, 1600, 1800, 2200, 2500];
        this.topGames = options.topGames || 0;
        this.recentGames = options.recentGames || 0;
    }

    /**
     * Constructs the query parameters for the API request.
     * @param {string} fen - The FEN string representing the chess position.
     * @returns {string} - The encoded query string.
     */
    buildQueryParams(fen) {
        const params = new URLSearchParams();
        params.append('variant', this.variant);
        params.append('speeds', this.speeds.join(','));
        params.append('ratings', this.ratings.join(','));
        params.append('fen', fen);
        params.append('topGames', this.topGames);
        params.append('recentGames', this.recentGames);

        const queryString = params.toString();
        console.log(`Constructed query parameters for FEN "${fen}":`, queryString);
        return queryString;
    }

    /**
     * Converts SAN castling notation to UCI format.
     * @param {string} san - The SAN move string.
     * @param {string} fen - The current FEN position to determine the side to move.
     * @returns {string|null} - The UCI format of the castling move or null if not a castling move.
     */
    convertSanToUci(san, fen) {
        const sideToMove = fen.split(' ')[1]; // 'w' for white, 'b' for black
        let uciMove = null;

        if (san === 'O-O') {
            // Kingside castling
            uciMove = sideToMove === 'w' ? 'e1g1' : 'e8g8';
            console.log(`Converted SAN castling "${san}" to UCI "${uciMove}" for side "${sideToMove}".`);
        } else if (san === 'O-O-O') {
            // Queenside castling
            uciMove = sideToMove === 'w' ? 'e1c1' : 'e8c8';
            console.log(`Converted SAN castling "${san}" to UCI "${uciMove}" for side "${sideToMove}".`);
        } else {
            // console.debug(`No castling conversion needed for SAN move "${san}".`);
        }

        return uciMove;
    }

    /**
     * Fetches the most probable next moves for a given FEN position.
     * @param {string} fen - The FEN string representing the chess position.
     * @returns {Promise<Array>} - A promise that resolves to an array of move objects.
     */
    async getNextMoves(fen) {
        console.info(`Fetching next moves for FEN: "${fen}"`);
        const queryParams = this.buildQueryParams(fen);
        const url = `${this.baseUrl}?${queryParams}`;
        console.debug(`API Request URL: ${url}`);

        try {
            const response = await fetch(url);
            console.debug(`Received response with status: ${response.status} ${response.statusText}`);

            if (!response.ok) {
                console.error(`API request failed with status ${response.status}: ${response.statusText}`);
                throw new Error(`API request failed with status ${response.status}: ${response.statusText}`);
            }

            const data = await response.json();
            console.debug('API Response Data:', data);

            if (!data.moves || !Array.isArray(data.moves)) {
                console.error('Invalid API response: "moves" field is missing or not an array.', data);
                throw new Error('Invalid API response: "moves" field is missing or not an array.');
            }

            if (data.moves.length === 0) {
                console.warn('API response contains an empty "moves" array.');
            }

            // Calculate total games for each move and convert castling moves to UCI
            const moves = data.moves.map(move => {
                let uci = move.uci;

                // Check for castling in SAN and convert to UCI if necessary
                const castlingMove = this.convertSanToUci(move.san, fen);
                if (castlingMove) {
                    uci = castlingMove;
                }

                return {
                    uci,
                    san: move.san,
                    averageRating: move.averageRating,
                    white: move.white,
                    draws: move.draws,
                    black: move.black,
                    totalGames: move.white + move.draws + move.black
                };
            }).sort((a, b) => b.totalGames - a.totalGames);

            if (moves.length === 0) {
                console.warn('After processing, no moves are available for the given position.');
            } else {
                console.info(`Fetched ${moves.length} moves for FEN: "${fen}"`);
            }

            return moves;
        } catch (error) {
            console.error(`Error fetching next moves: ${error.message}`, error);
            throw error;
        }
    }
}

export default LichessExplorer;
