import React from 'react';
import ReactDOM from 'react-dom/client'; // Use 'react-dom/client' for createRoot
import { AppRegistry, Platform } from 'react-native';
import App from './App';
import appConfig from './app.json';

// For Web
if (Platform.OS === 'web') {
  const rootElement = document.getElementById('root');

  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement); // createRoot for React 18
    root.render(<App />);
  }
}

// For Mobile (iOS/Android)
AppRegistry.registerComponent(appConfig.name, () => App);
