// components/BaseSideMenu.js

import React from 'react';
import {
  View,
  Animated,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';

const BaseSideMenu = ({
  isVisible,
  onClose,
  position = 'right',
  children,
  width = 200,
}) => {
  const initialValue = position === 'right' ? width : -width;
  const slideAnim = React.useRef(new Animated.Value(initialValue)).current;
  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    const toValue = isVisible ? 0 : initialValue;
    Animated.parallel([
      Animated.timing(slideAnim, {
        toValue: toValue,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(fadeAnim, {
        toValue: isVisible ? 0.5 : 0,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start();
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <TouchableWithoutFeedback onPress={onClose}>
          <Animated.View style={[styles.backdrop, { opacity: fadeAnim }]} />
        </TouchableWithoutFeedback>
      )}
      <Animated.View
        style={[
          styles.menu,
          {
            transform: [{ translateX: slideAnim }],
            [position]: 0,
            width: width,
          },
        ]}
      >
        {children}
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    zIndex: 1000,
  },
  menu: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: '#1f2937',
    padding: 20,
    zIndex: 1001,
    display: 'flex',
  },
});

export default BaseSideMenu;
