// src/components/ChessEngine.js

import { Platform } from 'react-native';
import EventEmitter from 'events';

// Detect the platform: 'web', 'ios', 'android'
const isWeb = Platform.OS === 'web';

class ChessEngine extends EventEmitter {
    constructor() {
        super();
        this.engine = null; // Web Worker or react-native Thread
        this.isReadyFlag = false;
        this.commandQueue = []; // Queue to manage commands
        this.currentResolve = null; // Resolve function for the current command
        this.currentReject = null;  // Reject function for the current command
        this.timer = null; // Timer for maxTime

        // Initialize readyPromise
        this.readyPromise = new Promise((resolve, reject) => {
            this.readyResolve = resolve;
            this.readyReject = reject;
        });

        this.initEngine();
    }

    async initEngine() {
        if (this.isReadyFlag) {
            return;
        }

        if (isWeb) {
            // Web environment: Use Stockfish worker directly
            const wasmSupported =
                typeof WebAssembly === 'object' &&
                WebAssembly.validate(
                    new Uint8Array([
                        0x00, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00,
                    ])
                );
            const stockfishScript = wasmSupported
                ? '/stockfish.wasm.js'
                : '/stockfish.js';
            this.engine = new Worker(stockfishScript);

            // Set up Web Worker message handler
            this.engine.onmessage = this.handleMessage.bind(this);
        } else if (Platform.OS === 'ios' || Platform.OS === 'android') {
            // Native environment: Use react-native-threads
            const { Thread } = require('react-native-threads');
            this.engine = new Thread('../threads/stockfishThread.js');

            // Set up native thread message handler
            this.engine.onmessage = this.handleMessage.bind(this);
        } else {
            throw new Error('Unsupported platform');
        }

        // Enqueue the 'uci' command to initialize the engine
        this.commandQueue.push({
            command: 'uci',
            resolve: null,
            reject: null,
        });

        // Start processing the command queue
        this.processQueue();
    }

    // Handle incoming messages from the worker/thread
    handleMessage(messageEvent) {
        const message = messageEvent.data;

        // Check if the engine is ready
        if (message.trim() === 'uciok') {
            this.isReadyFlag = true;
            if (this.readyResolve) {
                this.readyResolve();
            }
        }

        if (this.currentResolve) {
            // Pass the message to the current resolver
            this.currentResolve(message);
        }

        // Continue processing the queue
        this.processQueue();
    }

    // Send a command to Stockfish
    sendCommand(command) {
        this.engine.postMessage(command);
    }

    // Process the next command in the queue
    processQueue() {
        if (this.currentResolve || this.commandQueue.length === 0) {
            return;
        }

        const { command, resolve, reject } = this.commandQueue.shift();
        this.currentResolve = resolve;
        this.currentReject = reject;
        this.sendCommand(command);
    }

    // Enqueue a command with its resolver
    enqueueCommand(command, resolve, reject) {
        this.commandQueue.push({ command, resolve, reject });
        this.processQueue();
    }

    // Async method to check if the engine is ready
    async isReady() {
        if (this.isReadyFlag) {
            return true;
        } else {
            return this.readyPromise;
        }
    }

    // Start analysis on a position (common logic for getBestMoves and streamBestMoves)
    startAnalysis(fen, options = {}) {
        const {
            multipv = 5,
            depth = 15,
            minDepth = 5,
            maxTime = 5000,
            callback,
        } = options;

        const bestMoves = [];
        const receivedMultipv = {}; // Dictionary to track max depth for each multipvIndex

        // Initialize receivedMultipv with 0 for all multipv indices
        for (let i = 1; i <= multipv; i++) {
            receivedMultipv[i] = 0;
        }

        // Listener for multipv info
        const handleMultipv = (message) => {
            const infoRegex =
                /info .*?depth (\d+).*?multipv (\d+).*?score (\w+) (-?\d+).*?pv (.+)/;
            const infoMatch = message.match(infoRegex);

            if (infoMatch) {
                const currentDepth = parseInt(infoMatch[1], 10);
                const multipvIndex = parseInt(infoMatch[2], 10);
                const scoreType = infoMatch[3];
                const scoreValue = parseInt(infoMatch[4], 10);
                const pvMoves = infoMatch[5];

                let score;
                if (scoreType === 'cp') {
                    score = scoreValue; // Score in centipawns
                } else if (scoreType === 'mate') {
                    // Assign a large positive or negative value for mate scores
                    score = scoreValue > 0 ? 100000 : -100000;
                }

                // Update the best move for the current multipvIndex
                bestMoves[multipvIndex - 1] = {
                    move: pvMoves.split(' ')[0], // First move in the PV
                    score,
                    rank: multipvIndex,
                    note: `Depth: ${currentDepth}`,
                    winrate: 0, // Stockfish does not provide winrate
                };

                // Update the max depth reached for this multipvIndex
                receivedMultipv[multipvIndex] = currentDepth;

                // Emit event or call callback with current best moves
                if (callback && typeof callback === 'function') {
                    callback(bestMoves);
                } else {
                    this.emit('bestMoves', bestMoves);
                }

                // Check if all entries in receivedMultipv have reached the desired depth
                const allDepthsReached = Object.values(receivedMultipv).every(
                    (val) => val >= depth
                );

                if (allDepthsReached) {
                    clearTimeout(this.timer); // Clear the timeout
                    this.engine.postMessage('stop'); // Stop the engine
                    if (this.analysisResolve) {
                        this.analysisResolve(
                            bestMoves.filter((move) => move) // Remove undefined entries
                        );
                    }
                    // Clean up
                    this.currentResolve = null;
                    this.currentReject = null;
                }
            }
        };

        // Start the analysis
        return new Promise((resolve, reject) => {
            this.analysisResolve = resolve;
            this.analysisReject = reject;

            // Timer to enforce maxTime
            const checkMinDepthReached = () => {
                // Check if minDepth has been reached for at least one multipvIndex
                const minDepthReached = Object.values(receivedMultipv).some(
                    (val) => val >= minDepth
                );

                if (minDepthReached) {
                    console.warn(
                        `Max time of ${maxTime}ms reached. Min depth reached. Returning best moves found so far.`
                    );
                    // Stop the engine's search
                    this.engine.postMessage('stop');
                    // Resolve with the best moves collected so far
                    if (bestMoves.length > 0) {
                        resolve(
                            bestMoves.filter((move) => move) // Remove undefined entries
                        );
                    } else {
                        reject(
                            new Error(
                                'ChessEngine.startAnalysis: No moves found within the specified maxTime and minDepth'
                            )
                        );
                    }
                    // Clean up
                    this.currentResolve = null;
                    this.currentReject = null;
                } else {
                    console.warn(
                        `Max time of ${maxTime}ms reached but minDepth ${minDepth} not yet reached. Continuing analysis.`
                    );
                    // Set another timer to check again after 1000ms
                    this.timer = setTimeout(checkMinDepthReached, 1000);
                }
            };

            this.timer = setTimeout(checkMinDepthReached, maxTime);

            // Enqueue the commands
            this.enqueueCommand(
                `setoption name MultiPV value ${multipv}`,
                null,
                null
            );
            this.enqueueCommand(`position fen ${fen}`, null, null);
            this.enqueueCommand(
                `go depth ${depth}`,
                handleMultipv,
                reject
            );
        });
    }

    // Get the top moves from Stockfish using multipv
    async getBestMoves(fen, multipv = 5, depth = 15, minDepth = 5, maxTime = 5000) {
        await this.isReady();

        return this.startAnalysis(fen, {
            multipv,
            depth,
            minDepth,
            maxTime,
            callback: null, // No callback needed for getBestMoves
        });
    }

    // Stream the best moves as they are found
    async streamBestMoves(fen, multipv = 5, depth = 15, minDepth = 5, maxTime = 5000) {
        await this.isReady();

        // Start analysis without waiting for it to finish
        this.startAnalysis(fen, {
            multipv,
            depth,
            minDepth,
            maxTime,
            callback: null, // Use event emitter to stream updates
        });
    }

    // Stop the current analysis
    stopAnalysis() {
        this.engine.postMessage('stop');
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        // Clean up
        this.currentResolve = null;
        this.currentReject = null;
    }
}

export default ChessEngine;
