// components/TrainerLogic.js

import { useState, useEffect, useMemo } from 'react';
import { Chess } from 'chess.js';
import LichessExplorer from './lichessExplorer';
import ChessDB from './chessDB';
import ChessEngine from './ChessEngine';
import assert from 'assert';

const TOTAL_MOVES = 10;
const mistake_threshold = 30;

const useTrainerLogic = (pgn, playerColor, selectedElo, onCompletion) => {
  const [game] = useState(new Chess());
  const [moves, setMoves] = useState([]);
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [mistakeSquare, setMistakeSquare] = useState(null);
  const [hintSquares, setHintSquares] = useState([]);
  const [moveRankings, setMoveRankings] = useState([]);
  const [moveEvaluations, setMoveEvaluations] = useState({}); // State to store evaluations

  const chessDB = useMemo(() => new ChessDB(), []);

  const chessEngine = useMemo(() => new ChessEngine(), []);
  const evaluationEngine = useMemo(() => new ChessEngine(), []); // Dedicated memoized instance for evaluations

  const trainerColor = useMemo(() => (playerColor === 'w' ? 'b' : 'w'), [playerColor]);

  const explorer = useMemo(
    () =>
      new LichessExplorer({
        variant: 'standard',
        speeds: ['blitz', 'rapid', 'classical'],
        ratings: [selectedElo],
        topGames: 0,
        recentGames: 0,
      }),
    []
  );

  // === Scoring Metrics State ===

  const [bookMovesCount, setBookMovesCount] = useState(0);
  const [nonBookMovesCount, setNonBookMovesCount] = useState(0);
  const [bestMovesCount, setBestMovesCount] = useState(0);
  const [greatMovesCount, setGreatMovesCount] = useState(0);
  const [mistakesCount, setMistakesCount] = useState(0);
  const [hintsCount, setHintsCount] = useState(0);
  const [totalCentipawnLoss, setTotalCentipawnLoss] = useState(0);
  const [finalEngineEvaluation, setFinalEngineEvaluation] = useState(null);
  const [hintUsedForMove, setHintUsedForMove] = useState(new Set());

  useEffect(() => {
    explorer.ratings = [selectedElo];
  }, [selectedElo]);

  useEffect(() => {
    loadPgn(pgn);
  }, [pgn]);

  const getMoveIndex = () => {
    return game.history().length;
  };

  const gotoMoveIndex = (fullMoves, moveIndex) => {
    const tempGame = new Chess();

    for (let i = 0; i < moveIndex; i++) {
      if (i >= fullMoves.length) {
        break;
      }
      const move = fullMoves[i];
      tempGame.move({
        from: move.from,
        to: move.to,
        promotion: move.promotion,
      });
    }
    game.loadPgn(tempGame.pgn());
  };

  const loadPgn = (pgnString, moveIndex = 0) => {
    try {
      resetGame();
      const tempGame = new Chess();
      tempGame.loadPgn(pgnString);
      const fullMoves = tempGame.history({ verbose: true });
      setMoves(fullMoves);
      gotoMoveIndex(fullMoves, moveIndex);

      if (game.turn() === trainerColor && !isGameOver()) {
        handleTrainerMove();
      } else {
        updateMoveRankings();
      }
    } catch (error) {
      console.error('Error loading PGN:', error);
      console.error('An exception occurred while loading PGN');
    }
  };

  const resetGame = (doPlay = false) => {
    game.reset();
    setCurrentMoveIndex(0);
    setMistakeSquare(null);
    setHintSquares([]);
    setMoveRankings([]);
    setMoveEvaluations({}); // Reset evaluations

    // Reset Scoring Metrics
    setBookMovesCount(0);
    setNonBookMovesCount(0);
    setBestMovesCount(0);
    setGreatMovesCount(0);
    setMistakesCount(0);
    setHintsCount(0);
    setTotalCentipawnLoss(0);
    setFinalEngineEvaluation(null);
    setHintUsedForMove(new Set());

    if (doPlay) {
      if (game.turn() === trainerColor && !isGameOver()) {
        handleTrainerMove();
      }
    }

    if (onCompletion) {
      onCompletion(false);
    }
  };

  const handleUserMove = async (move, promotion) => {
    if (isGameOver()) {
      console.log('Info: You have reached the total number of moves!');
      return false;
    }

    if (isWithinPredefinedMoves()) {
      return await handlePredefinedMove(move, promotion);
    } else {
      return await handleChessDBMove(move, promotion);
    }
  };

  const getTotalHalfMoves = () => {
    return playerColor === 'w' ? TOTAL_MOVES * 2 - 1 : TOTAL_MOVES * 2;
  };

  const isGameOver = () => {
    return getMoveIndex() >= getTotalHalfMoves();
  };

  const isWithinPredefinedMoves = () => {
    return getMoveIndex() < moves.length;
  };

  const handlePredefinedMove = async (move, promotion) => {
    const expectedMove = moves[getMoveIndex()];
    if (validatePredefinedMove(move, expectedMove)) {
      setBookMovesCount((prev) => prev + 1);
      const userMoveSuccess = applyMove(move, promotion);
      if (userMoveSuccess) {
        addBookMoveEvaluation(getMoveIndex());
        await handleTrainerMove();
      } else {
        throw new Error('Failed to apply user move');
      }
      return userMoveSuccess;
    } else {
      evaluateMove(move, promotion, 1);
      setNonBookMovesCount((prev) => prev + 1);
      handleIncorrectMove(move);
      return false;
    }
  };

  const validatePredefinedMove = (userMove, expectedMove) => {
    return userMove.from === expectedMove.from && userMove.to === expectedMove.to;
  };

  const handleChessDBMove = async (move, promotion) => {
    try {
      const availableMoves = moveRankings;

      if (!availableMoves || availableMoves.length === 0) {
        console.error('Error: No available moves found for the current position.');
        return false;
      }

      const topMove = availableMoves[0];
      const topScore = topMove.score;

      const userMoveStr = constructMoveString(move, promotion);
      evaluateMove(move, promotion);

      const userMoveData = availableMoves.find((m) => m.move === userMoveStr);

      if (!userMoveData) {
        setMistakesCount((prev) => prev + 1);
        handleIncorrectMove(move);
        return false;
      }

      const scoreDifference = topScore - userMoveData.score;

      if (scoreDifference === 0) {
        setBestMovesCount((prev) => prev + 1);
      } else if (scoreDifference > 0 && scoreDifference <= mistake_threshold) {
        setGreatMovesCount((prev) => prev + 1);
      } else if (scoreDifference > mistake_threshold) {
        setMistakesCount((prev) => prev + 1);
        handleIncorrectMove(move);
        return false;
      }

      setTotalCentipawnLoss((prev) => prev + scoreDifference);

      const userMoveSuccess = applyMove(move, promotion);
      if (userMoveSuccess) {
        await handleTrainerMove();
      }
      return userMoveSuccess;
    } catch (error) {
      console.error('Error validating move with ChessDB:', error);
      console.error('An error occurred while validating your move.');
      return false;
    }
  };

  const sortMovesByScore = (ranking) => {
    return ranking.sort((a, b) => {
      return b.score - a.score;
    });
  };

  const updateMoveRankings = async () => {
    try {
      const updatedFEN = game.fen();
      const rankings = await chessDB.getMoves(updatedFEN);
      const sortedRankings = sortMovesByScore(rankings);
      setMoveRankings(sortedRankings);
    } catch (error) {
      console.error('Error fetching moves from ChessDB:', error);
      return [];
    }
  };

  const constructMoveString = (move, promotion) => {
    let moveStr = `${move.from}${move.to}`;
    if (promotion) {
      moveStr += promotion.toLowerCase();
    }
    return moveStr;
  };

  const applyMove = (move, promotion) => {
    const moveResult = game.move({
      from: move.from,
      to: move.to,
      promotion: promotion,
    });

    if (!moveResult) {
      console.error('Invalid move:', move);
      return false;
    }
    setMoveRankings([]);
    setCurrentMoveIndex(getMoveIndex());
    setMistakeSquare(null);

    return true;
  };

  const evaluateMove = async (move, promotion, wrongBookMove = false) => {
    try {
      const tempGame = new Chess();
      tempGame.loadPgn(game.pgn());
      try {
        tempGame.move({
          from: move.from,
          to: move.to,
          promotion: promotion,
        }) 
      } catch (error) {
        console.warn('evaluateMove: Invalid move, no evaluation done.', error);
        return;
      }	

      // Use moveRankings to get the best move and its evaluation from previous position
      const bestMoveData = moveRankings[0];
      const bestMoveEvaluation = bestMoveData.score;

      // For the player's move, check if it's in moveRankings
      const playerMoveStr = constructMoveString(move, promotion);
      const playerMoveData = moveRankings.find((m) => m.move === playerMoveStr);
      let evaluationAfterPlayerMove;
      if (playerMoveData) {
        evaluationAfterPlayerMove = playerMoveData.score;
      } else {
        // Get evaluation after player's move
        const bestMovesAfterPlayer = await evaluationEngine.getBestMoves(tempGame.fen(), 1, 15, 10, 2000);
        evaluationAfterPlayerMove = bestMovesAfterPlayer[0].score*-1; // The evaluations is from the opponent's perspective, so we need to invert it
      }

      // Centipawn loss is evaluation after best move minus evaluation after player's move
      const centipawnLoss = bestMoveEvaluation - evaluationAfterPlayerMove;

      addMoveEvaluation(tempGame.history().length, centipawnLoss, wrongBookMove);
    } catch (error) {
      console.error('Error evaluating move:', error);
    }
  };  

  const addMoveEvaluation = (moveNumber, centipawnLoss, wrongBookMove = false) => {
    let moveCategory = null;
    if (centipawnLoss > 200) {
      moveCategory = 'blunder';
    } else if (centipawnLoss > 80) {
      moveCategory = 'mistake';
    } else if (centipawnLoss > 30) {
      moveCategory = 'inaccuracy';
    } else if (centipawnLoss > 0) {
      moveCategory = wrongBookMove ? 'wrongBook' : 'good';
    } else {
      moveCategory = wrongBookMove ? 'wrongBook' : 'best';
    }
  
    setMoveEvaluations((prevEvaluations) => ({
      ...prevEvaluations,
      [moveNumber]: { centipawnLoss, moveCategory },
    }));
    console.log('MoveEvaluation:', moveEvaluations[moveNumber]);
  };

  const addBookMoveEvaluation = (moveNumber) => {
    setMoveEvaluations((prevEvaluations) => ({
      ...prevEvaluations,
      [moveNumber]: { centipawnLoss : 0, moveCategory : 'book'},
    }));
    console.log('MoveEvaluation:', moveEvaluations[moveNumber]);
  };

  const handleTrainerMove = async () => {
    assert(game.turn() === trainerColor, "It is not the trainer's turn!");

    if (checkGameCompleted()) return;

    if (isWithinPredefinedMoves()) {
      await applyPredefinedTrainerMove();
    } else {
      try {
        await applyExplorerTrainerMove();
      } catch (error) {
        console.warn('Explorer move failed, falling back to stockfish:', error);
        await applyStockfishTrainerMove();
      }
    }
    await updateMoveRankings();
    setCurrentMoveIndex(getMoveIndex());
    checkGameCompleted();
  };

  const getStockfishMoves = async (fen) => {
    try {
      return chessEngine.getBestMoves(fen, 5, 15, 5, 2000);
    } catch (error) {
      console.error('Error fetching moves from Stockfish:', error);
      return [];
    }
  };

  const applyStockfishTrainerMove = async () => {
    try {
      const currentFEN = game.fen();
      const stockfishMoves = await getStockfishMoves(currentFEN);

      if (!stockfishMoves || stockfishMoves.length === 0) {
        console.error('Error: No moves found from Stockfish for the current position.');
        return;
      }

      const sortedMoves = sortMovesByScore(stockfishMoves);

      const topMove = sortedMoves[0];
      const moveResult = game.move({
        from: topMove.move.substring(0, 2),
        to: topMove.move.substring(2, 4),
        promotion: topMove.promotion || undefined,
      });

      if (!moveResult) {
        console.error('Error: Invalid move applied from Stockfish.');
        return;
      }
    } catch (error) {
      console.error('Error applying Stockfish trainer move:', error);
    }
  };

  const checkGameCompleted = () => {
    if (isGameOver() || game.isCheckmate()) {
      if (moveRankings && moveRankings.length > 0) {
        setFinalEngineEvaluation(moveRankings[0].score);
      } else {
        setFinalEngineEvaluation(null);
      }

      if (onCompletion) {
        onCompletion(true, game.isCheckmate());
      }
      return true;
    }
    return false;
  };

  const applyPredefinedTrainerMove = async () => {
    const trainerMove = moves[getMoveIndex()];
    try {
      await delay(300);
      const moveResult = game.move({
        from: trainerMove.from,
        to: trainerMove.to,
        promotion: trainerMove.promotion,
      });

      if (!moveResult) {
        throw new Error('Error: Invalid Trainer move applied.');
      }
    } catch (error) {
      console.error('Error applying Trainer move:', error);
      console.error('An error occurred while applying Trainer move.');
    }
  };

  const applyExplorerTrainerMove = async () => {
    try {
      const currentFEN = game.fen();
      const probableMoves = await explorer.getNextMoves(currentFEN);

      if (!probableMoves || probableMoves.length === 0) {
        throw new Error('Error: No games found for the current position.');
      }

      const totalGames = probableMoves.reduce((acc, move) => acc + move.totalGames, 0);
      if (totalGames === 0) {
        throw new Error('Error: No games found for the current position.');
      }

      const originalProbs = probableMoves.map((move) => (move.totalGames / totalGames) * 100);
      const flattenedProbs = flattenProbabilities(originalProbs, 0.9);
      console.log(
        'Original Probs:',
        probableMoves.map((move) => move.uci + ' ' + (move.totalGames * 100) / totalGames)
      );
      console.log('Flattened Probabilities:', flattenedProbs);
      const chosenMove = chooseMove(probableMoves, flattenedProbs);
      console.log('Chosen Move:', chosenMove);

      if (!chosenMove) {
        throw new Error('Error: Trainer could not select a move.');
      }

      const moveResult = game.move({
        from: chosenMove.uci.substring(0, 2),
        to: chosenMove.uci.substring(2, 4),
        promotion: chosenMove.promotion || undefined,
      });

      if (!moveResult) {
        throw new Error('Error: Invalid Trainer move applied.');
      }
    } catch (error) {
      console.error('Error fetching Trainer move:', error);
      throw error;
    }
  };

  const flattenProbabilities = (originalProbs, alpha) => {
    if (!Array.isArray(originalProbs) || originalProbs.length === 0) {
      throw new Error('originalProbs must be a non-empty array.');
    }
    if (typeof alpha !== 'number' || alpha <= 0) {
      throw new Error('alpha must be a positive number.');
    }

    const originalDecimals = originalProbs.map((p) => p / 100);

    const transformed = originalDecimals.map((p) => Math.pow(p, alpha));

    const sumTransformed = transformed.reduce((acc, val) => acc + val, 0);

    if (sumTransformed === 0) {
      throw new Error(
        'Sum of transformed probabilities is zero. Check your input probabilities and alpha.'
      );
    }

    const flattenedProbs = transformed.map((p) => (p / sumTransformed) * 100);

    const flattenedProbsRounded = flattenedProbs.map((p) => Math.round(p * 100) / 100);

    return flattenedProbsRounded;
  };

  const chooseMove = (moves, probabilities) => {
    if (moves.length !== probabilities.length) {
      console.error('Moves and probabilities arrays must be of the same length.');
      return null;
    }

    const cumulativeProbs = [];
    probabilities.reduce((acc, prob, index) => {
      cumulativeProbs[index] = acc + prob;
      return cumulativeProbs[index];
    }, 0);

    const rand = Math.random() * 100;

    for (let i = 0; i < cumulativeProbs.length; i++) {
      if (rand <= cumulativeProbs[i]) {
        return moves[i];
      }
    }

    return moves[moves.length - 1];
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleIncorrectMove = (move) => {
    console.warn('Incorrect Move: Try again!');
    setMistakeSquare(move.to);
    setTimeout(() => setMistakeSquare(null), 500);
  };

  const handleMove = async (move, promotion) => {
    const previousFen = game.fen(); // FEN before the move
    const moveSuccess = await handleUserMove(move, promotion);
    if (moveSuccess && game.turn() === trainerColor && !isGameOver()) {
      await handleTrainerMove();
    }
    return moveSuccess;
  };

  const showHint = () => {
    const currentIndex = getMoveIndex();
    if (isWithinPredefinedMoves()) {
      const expectedMove = moves[currentIndex];
      setHintSquares([expectedMove.from]);
      setHintsCount((prev) => prev + 1);
      setTimeout(() => setHintSquares([]), 2000);

      if (!hintUsedForMove.has(currentIndex)) {
        setHintUsedForMove((prev) => new Set(prev).add(currentIndex));
      }
    } else {
      if (moveRankings && moveRankings.length > 0) {
        const topScore = moveRankings[0].score;
        const validMoves = moveRankings.filter((m) => topScore - m.score <= mistake_threshold);
        const squares = validMoves.map((m) => m.move.substring(0, 2));
        if (squares.length > 0) {
          setHintSquares(squares);
          if (!hintUsedForMove.has(currentIndex)) {
            setHintsCount((prev) => prev + 1);
            setHintUsedForMove((prev) => new Set(prev).add(currentIndex));
          }
          setTimeout(() => setHintSquares([]), 2000);
        } else {
          console.warn('Hint Unavailable: No hints available for this position.');
        }
      } else {
        console.warn('Hint Unavailable: No hints available for this position.');
      }
    }
  };

  return {
    game,
    moves,
    currentMoveIndex,
    mistakeSquare,
    hintSquares,
    handleMove,
    showHint,
    resetGame,
    loadPgn,
    bookMovesCount,
    nonBookMovesCount,
    bestMovesCount,
    greatMovesCount,
    mistakesCount,
    hintsCount,
    totalCentipawnLoss,
    finalEngineEvaluation,
    moveEvaluations, // Expose evaluations
  };
};

export default useTrainerLogic;
