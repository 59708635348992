import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';

const openings = [
  { name: "Ruy Lopez", pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5" },
  { name: "Sicilian Defense", pgn: "1. e4 c5" },
  { name: "French Defense", pgn: "1. e4 e6" },
  { name: "Queen's Gambit", pgn: "1. d4 d5 2. c4" },
  { name: "King's Indian", pgn: "1. d4 Nf6 2. c4 g6" },
  { name: "Caro-Kann", pgn: "1. e4 c6" },
  { name: "English Opening", pgn: "1. c4" },
  { name: "Pirc Defense", pgn: "1. e4 d6" },
  { name: "Scandinavian Defense", pgn: "1. e4 d5" },
  { name: "Alekhine's Defense", pgn: "1. e4 Nf6" },
  { name: "Italian Game", pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4" },
  { name: "Slav Defense", pgn: "1. d4 d5 2. c4 c6" },
];

const openingsWithColors = openings.flatMap(opening => [
  { ...opening, color: 'white', playerColor: 'w' },
  { ...opening, color: 'black', playerColor: 'b' }
]);

const ChessOpeningGrid = ({ onSelectOpening, onExploreOpening }) => {
  const [buttonWidth, setButtonWidth] = useState(200);
  const [selectedColor, setSelectedColor] = useState('white');

  const updateButtonWidth = () => {
    const screenWidth = Dimensions.get('window').width;
    const containerPadding = 32;
    const minButtonsPerRow = 4;
    const buttonMargin = 5;
    const availableWidth = screenWidth - containerPadding;

    let buttonsPerRow = Math.floor(availableWidth / (200 + buttonMargin));
    buttonsPerRow = Math.max(minButtonsPerRow, buttonsPerRow);

    const calculatedWidth = (availableWidth - (buttonsPerRow - 1) * buttonMargin) / buttonsPerRow;
    setButtonWidth(Math.min(200, calculatedWidth));
  };

  useEffect(() => {
    updateButtonWidth();
    const subscription = Dimensions.addEventListener('change', updateButtonWidth);
    return () => {
      if (subscription?.remove) {
        subscription.remove();
      } else {
        Dimensions.removeEventListener('change', updateButtonWidth);
      }
    };
  }, []);

  const filteredOpenings = openingsWithColors.filter(opening => opening.color === selectedColor);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Chess Openings</Text>
        <View style={styles.segmentedControl}>
          <TouchableOpacity
            style={[
              styles.segmentButton,
              selectedColor === 'white' ? styles.activeButton : styles.inactiveButton
            ]}
            onPress={() => setSelectedColor('white')}
          >
            <Text style={selectedColor === 'white' ? styles.activeText : styles.whiteText}>
              {selectedColor === 'white' ? '♔ White' : 'White'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.segmentButton,
              selectedColor === 'black' ? styles.activeButton : styles.inactiveButton
            ]}
            onPress={() => setSelectedColor('black')}
          >
            <Text style={selectedColor === 'black' ? styles.blackText : styles.blackText}>
              {selectedColor === 'black' ? '♚ Black' : 'Black'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.grid}>
        {filteredOpenings.map((opening, index) => (
          <View key={`${opening.name}-${opening.color}-${index}`} style={styles.openingContainer}>
            <HoverableButton
              opening={opening}
              onPress={() => onSelectOpening(opening)}
              width={buttonWidth}
            />
            {/* <TouchableOpacity
              style={styles.exploreButton}
              onPress={() => onExploreOpening(opening)}
            >
              <Text style={styles.exploreButtonText}>Explore</Text>
            </TouchableOpacity> */}
          </View>
        ))}
      </View>
    </View>
  );
};

const HoverableButton = ({ opening, onPress, width }) => {
  const [isHovered, setIsHovered] = useState(false);
  const buttonRef = useRef(null);

  const buttonStyle = [
    styles.openingButton,
    opening.color === 'white' ? styles.whiteButton : styles.blackButton,
    isHovered ? styles.hoveredButton : null,
    { width: width }
  ];

  const textStyle = [
    styles.openingName,
    opening.color === 'black' ? styles.whiteText : styles.blackText,
  ];

  const colorTextStyle = [
    styles.openingColor,
    opening.color === 'black' ? styles.whiteText : styles.blackText,
  ];

  return (
    <TouchableOpacity
      ref={buttonRef}
      style={buttonStyle}
      onPress={onPress}
      onPressIn={() => setIsHovered(true)}
      onPressOut={() => setIsHovered(false)}
    >
      <View style={styles.buttonContent}>
        <Text style={textStyle} numberOfLines={1} ellipsizeMode="tail">{opening.name}</Text>
        <Text style={colorTextStyle}>{opening.color}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: '#1f2937',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#fff',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
  },
  segmentedControl: {
    flexDirection: 'row',
    borderRadius: 8,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#ccc',
    width: 160,
  },
  segmentButton: {
    flex: 1,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeButton: {
    backgroundColor: '#8B4513',
  },
  inactiveButton: {
    backgroundColor: '#D2B48C',
  },
  activeText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  whiteText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  blackText: {
    color: '#000',
    fontWeight: 'bold',
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginRight: -5,
  },
  openingContainer: {
    marginBottom: 5,
    marginRight: 5,
  },
  openingButton: {
    height: 70,
    padding: 8,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  whiteButton: {
    backgroundColor: '#f3f4f6',
  },
  blackButton: {
    backgroundColor: '#000000',
  },
  hoveredButton: {
    opacity: 0.8,
  },
  openingName: {
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
  },
  openingColor: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    marginTop: 4,
  },
  exploreButton: {
    backgroundColor: '#4b5563',
    padding: 8,
    borderRadius: 4,
    marginTop: 4,
    alignItems: 'center',
  },
  exploreButtonText: {
    color: '#fff',
    fontSize: 12,
  },
});

export default ChessOpeningGrid;