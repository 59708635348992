import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { Chess } from 'chess.js';
import Board from './Board';
import LichessExplorer from './lichessExplorer';
import ChessDB from './chessDB';

const OpeningExplorer = ({ opening, onBack }) => {
  const [game, setGame] = useState(new Chess());
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const explorer = new LichessExplorer({
    variant: 'standard',
    speeds: ['blitz', 'rapid', 'classical'],
    ratings: [1600, 1800, 2000, 2200, 2500],
  });

  const chessDB = new ChessDB();

  useEffect(() => {
    loadOpening();
  }, [opening]);

  const loadOpening = () => {
    const newGame = new Chess();
    newGame.loadPgn(opening.pgn);
    setGame(newGame);
    setCurrentMoveIndex(newGame.history().length);
    exploreBranches(newGame.fen());
  };

  const exploreBranches = async (fen) => {
    try {
      const [lichessMoves, chessDBMoves] = await Promise.all([
        explorer.getNextMoves(fen),
        chessDB.getMoves(fen)
      ]);

      const combinedMoves = mergeMoves(lichessMoves, chessDBMoves);
      setBranches(combinedMoves);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const mergeMoves = (lichessMoves, chessDBMoves) => {
    const moveMap = new Map();

    lichessMoves.forEach(move => {
      moveMap.set(move.uci, {
        ...move,
        lichessGames: move.totalGames,
        lichessScore: move.winningPercentage,
      });
    });

    chessDBMoves.forEach(move => {
      if (moveMap.has(move.uci)) {
        const existingMove = moveMap.get(move.uci);
        moveMap.set(move.uci, {
          ...existingMove,
          chessDBGames: move.totalGames,
          chessDBScore: move.score,
        });
      } else {
        moveMap.set(move.uci, {
          uci: move.uci,
          san: move.san,
          chessDBGames: move.totalGames,
          chessDBScore: move.score,
        });
      }
    });

    return Array.from(moveMap.values());
  };

  const handleMoveClick = (move) => {
    const newGame = new Chess(game.fen());
    newGame.move(move.san);
    setGame(newGame);
    setCurrentMoveIndex(newGame.history().length);
    setSelectedBranch(move);
    exploreBranches(newGame.fen());
  };

  const handleBack = () => {
    if (currentMoveIndex > 0) {
      const newGame = new Chess();
      const history = game.history({ verbose: true });
      for (let i = 0; i < currentMoveIndex - 1; i++) {
        newGame.move(history[i]);
      }
      setGame(newGame);
      setCurrentMoveIndex(currentMoveIndex - 1);
      setSelectedBranch(null);
      exploreBranches(newGame.fen());
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.backButton} onPress={onBack}>
        <Text style={styles.backButtonText}>← Back to Openings</Text>
      </TouchableOpacity>
      <Text style={styles.title}>{opening.name} ({opening.color})</Text>
      <View style={styles.content}>
        <View style={styles.boardContainer}>
          <Board game={game} playerColor={opening.color === 'white' ? 'w' : 'b'} />
          <TouchableOpacity style={styles.undoButton} onPress={handleBack}>
            <Text style={styles.undoButtonText}>Undo Move</Text>
          </TouchableOpacity>
        </View>
        <ScrollView style={styles.branchesContainer}>
          {branches.map((branch, index) => (
            <TouchableOpacity
              key={index}
              style={[
                styles.branchButton,
                selectedBranch === branch && styles.selectedBranch
              ]}
              onPress={() => handleMoveClick(branch)}
            >
              <Text style={styles.branchText}>{branch.san}</Text>
              <Text style={styles.branchStats}>
                Lichess: {branch.lichessGames || 0} games, {branch.lichessScore || 0}% win
              </Text>
              <Text style={styles.branchStats}>
                ChessDB: {branch.chessDBGames || 0} games, Score: {branch.chessDBScore || 0}
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#1f2937',
  },
  backButton: {
    marginBottom: 16,
  },
  backButtonText: {
    color: '#fff',
    fontSize: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: 16,
  },
  content: {
    flexDirection: 'row',
    flex: 1,
  },
  boardContainer: {
    flex: 1,
    marginRight: 16,
  },
  undoButton: {
    backgroundColor: '#4b5563',
    padding: 8,
    borderRadius: 4,
    marginTop: 8,
    alignItems: 'center',
  },
  undoButtonText: {
    color: '#fff',
  },
  branchesContainer: {
    flex: 1,
  },
  branchButton: {
    backgroundColor: '#374151',
    padding: 12,
    borderRadius: 4,
    marginBottom: 8,
  },
  selectedBranch: {
    backgroundColor: '#4b5563',
  },
  branchText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  branchStats: {
    color: '#9ca3af',
    fontSize: 12,
    marginTop: 4,
  },
});

export default OpeningExplorer;