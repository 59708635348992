// components/MoveHistory.js

import React, { useEffect, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';

// Utility function to replace piece identifiers with Unicode symbols only for the first character
const pieceToUnicode = (move) => {
  const pieceSymbols = {
    K: '\u2654', // White King
    Q: '\u2655', // White Queen
    R: '\u2656', // White Rook
    B: '\u2657', // White Bishop
    N: '\u2658', // White Knight
    k: '\u265A', // Black King
    q: '\u265B', // Black Queen
    r: '\u265C', // Black Rook
    b: '\u265D', // Black Bishop
    n: '\u265E', // Black Knight
  };

  const firstChar = move.charAt(0);
  const restOfMove = move.slice(1);

  if (pieceSymbols[firstChar]) {
    return pieceSymbols[firstChar] + restOfMove;
  }

  return move;
};

const MoveHistory = ({ history, onSelectMove, currentMoveIndex }) => {
  const flatListRef = useRef();

  const moveData = history.map((move, index) => {
    return {
      index,
      moveNumber: Math.floor(index / 2) + 1,
      side: index % 2 === 0 ? 'w' : 'b',
      move: pieceToUnicode(move),
    };
  });

  useEffect(() => {
    if (flatListRef.current && moveData.length > 0) {
      let indexToScroll = currentMoveIndex - 1;
      if (indexToScroll < 0) indexToScroll = 0;
      if (indexToScroll >= moveData.length) indexToScroll = moveData.length - 1;

      flatListRef.current.scrollToIndex({
        index: indexToScroll,
        animated: true,
        viewPosition: 0.5,
      });
    }
  }, [currentMoveIndex, moveData]);

  const handleBack = () => {
    const newIndex = Math.max(currentMoveIndex - 1, 0);
    onSelectMove(newIndex - 1);
  };

  const handleForward = () => {
    const newIndex = Math.min(currentMoveIndex + 1, history.length);
    onSelectMove(newIndex - 1);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleBack} style={styles.arrowButton}>
        <Text style={styles.arrowText}>←</Text>
      </TouchableOpacity>
      <FlatList
        horizontal
        ref={flatListRef}
        data={moveData}
        keyExtractor={(item) => item.index.toString()}
        renderItem={({ item, index }) => {
          const isCurrentMove = index + 1 === currentMoveIndex;
          return (
            <TouchableOpacity
              onPress={() => onSelectMove && onSelectMove(item.index)}
            >
              <View
                style={[
                  styles.moveContainer,
                  isCurrentMove && styles.currentMoveContainer,
                ]}
              >
                {item.side === 'w' && (
                  <Text style={styles.moveNumber}>{item.moveNumber}.</Text>
                )}
                <Text style={styles.move}>{item.move}</Text>
              </View>
            </TouchableOpacity>
          );
        }}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollContainer}
        getItemLayout={(data, index) => ({
          length: 40,
          offset: 40 * index,
          index,
        })}
      />
      <TouchableOpacity onPress={handleForward} style={styles.arrowButton}>
        <Text style={styles.arrowText}>→</Text>
      </TouchableOpacity>
    </View>
  );
};

MoveHistory.propTypes = {
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectMove: PropTypes.func,
  currentMoveIndex: PropTypes.number,
};

MoveHistory.defaultProps = {
  onSelectMove: () => {},
  currentMoveIndex: 0,
};

const styles = StyleSheet.create({
  container: {
    height: 30,
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  moveContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 3,
    paddingHorizontal: 2,
    paddingVertical: 1,
  },
  currentMoveContainer: {
    backgroundColor: 'gray',
    borderRadius: 3,
  },
  moveNumber: {
    fontSize: 14,
    color: '#fff',
    marginRight: 1,
  },
  move: {
    fontSize: 14,
    color: '#fff',
  },
  noHistoryText: {
    fontSize: 16,
    color: '#aaa',
  },
  arrowButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  arrowText: {
    color: '#fff',
    fontSize: 20,
  },
});

export default MoveHistory;
