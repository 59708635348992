// components/Sidebar.js

import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import BaseSideMenu from './BaseSideMenu';

const Sidebar = ({ isVisible, onClose, onSelectOpening, onOpenPGN }) => {
  return (
    <BaseSideMenu
      isVisible={isVisible}
      onClose={onClose}
      position="left"
      width={200}
    >
      <View style={styles.menuContainer}>
        <TouchableOpacity
          style={styles.menuItem}
          onPress={() => {
            onSelectOpening();
            onClose();
          }}
          accessibilityLabel="Select Opening"
          accessibilityHint="Opens the opening selection screen"
        >
          <Text style={styles.menuText}>Select Opening</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.menuItem}
          onPress={() => {
            onOpenPGN();
            onClose();
          }}
          accessibilityLabel="Open PGN"
          accessibilityHint="Opens the PGN input modal to paste your PGN"
        >
          <Text style={styles.menuText}>Open PGN</Text>
        </TouchableOpacity>
      </View>
    </BaseSideMenu>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    marginTop: 20,
  },
  menuItem: {
    padding: 15,
    borderRadius: 8,
    backgroundColor: '#2563eb',
    marginBottom: 10,
  },
  menuText: {
    fontSize: 14,
    color: '#fff',
    fontWeight: 'bold',
  },
});

export default Sidebar;
