import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

const MenuButton = ({ onPress, style, buttonStyle, lineStyle }) => {
  return (
    <TouchableOpacity 
      style={[styles.button, buttonStyle, style]} 
      onPress={onPress}
    >
      <View style={[styles.menuLine, lineStyle]} />
      <View style={[styles.menuLine, lineStyle]} />
      <View style={[styles.menuLine, lineStyle]} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: '#2563eb',
    zIndex: 1,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuLine: {
    width: '80%',
    height: 2,
    backgroundColor: '#fff',
    marginVertical: 2,
    borderRadius: 1,
  },
});

export default MenuButton;