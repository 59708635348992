// components/chessDB.js

import ChessEngine from './ChessEngine';

class ChessDB {
    constructor() {
        this.chessDBUrl = 'http://www.chessdb.cn/cdb.php?action=queryall&board=';
        this.lichessApiUrl = 'https://lichess.org/api/cloud-eval';
        this.maxRetries = 1;
        this.retryDelay = 500; // in milliseconds
        this.chessEngine = new ChessEngine(); // Initialize ChessEngine
    }

    async getMoves(fen, numberOfMoves = 5) {
        try {
            // Start Stockfish calculation but do not await it yet
            const stockfishPromise = this.getStockfishMoves(fen, numberOfMoves);

            const allMovesMap = new Map();

            // Try to get moves from Lichess API
            const lichessMoves = await this.getLichessMoves(fen, numberOfMoves);
            for (const move of lichessMoves) {
                move.source = 'lichess';
                allMovesMap.set(move.move, move);
            }

            // If we have enough moves, stop here
            if (allMovesMap.size < numberOfMoves) {
                // Try to get moves from ChessDB
                const chessDBMoves = await this.getChessDBMoves(fen);
                for (const move of chessDBMoves) {
                    move.source = 'chessDB';
                    if (!allMovesMap.has(move.move)) {
                        allMovesMap.set(move.move, move);
                    }
                }
            }

            // If we still need more moves, use Stockfish
            if (allMovesMap.size < numberOfMoves) {
                // Await the Stockfish promise
                const stockfishMoves = await stockfishPromise;
                for (const move of stockfishMoves) {
                    move.source = 'stockfish';
                    if (!allMovesMap.has(move.move)) {
                        allMovesMap.set(move.move, move);
                    }
                }
            } else {
                // Stop Stockfish calculation
                this.chessEngine.stopAnalysis();
            }

            // Convert map values to array
            let allMoves = Array.from(allMovesMap.values());

            // Order the moves by score in descending order
            allMoves.sort((a, b) => b.score - a.score);

            // Return up to numberOfMoves moves
            return allMoves.slice(0, numberOfMoves);
        } catch (error) {
            console.error('Error in getMoves:', error);
            return [];
        }
    }

    // Fetch moves from ChessDB with retry logic
    async getChessDBMoves(fen) {
        const chessDBUrl = this.buildChessDBUrl(fen);
        for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
            try {
                const responseText = await this.fetchData(chessDBUrl);
                const parsedMoves = this.parseChessDBResponse(responseText);

                if (this.hasValidMoves(parsedMoves)) {
                    return parsedMoves;
                } else {
                    this.handleEmptyResponse(attempt, fen);
                    await this.delay(this.retryDelay);
                }
            } catch (error) {
                this.handleFetchError(attempt, error);
                if (attempt < this.maxRetries) {
                    await this.delay(this.retryDelay);
                }
            }
        }
        return [];
    }

    // Fetch moves from Lichess API and correct castling moves
    async getLichessMoves(fen, numberOfMoves) {
        const lichessMoves = await this.getLichessMovesInternal(fen, numberOfMoves);
        return lichessMoves;
    }

    // Fetch moves from Stockfish
    async getStockfishMoves(fen, numberOfMoves) {
        // Return the promise directly without awaiting
        return this.chessEngine.getBestMoves(fen, numberOfMoves, 15, 5, 2000); // Get top numberOfMoves moves with depth 15
    }

    buildChessDBUrl(fen) {
        return `${this.chessDBUrl}${encodeURIComponent(fen)}`;
    }

    async fetchData(url) {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        return await response.text();
    }

    parseChessDBResponse(response) {
        if (!response) {
            console.error('Received empty response from ChessDB for the given FEN.');
            return [];
        }

        const moveStrings = response.split('|').filter(moveStr => moveStr.includes(':'));

        if (moveStrings.length === 0) {
            console.error('No valid move data found in ChessDB response:', response);
            return [];
        }

        return moveStrings.map(moveStr => {
            const parts = moveStr.split(',');
            const moveData = {};

            parts.forEach(part => {
                const [key, ...value] = part.split(':');
                moveData[key] = value.join(':').trim();
            });

            return {
                move: moveData.move,
                score: parseInt(moveData.score, 10),
                rank: parseInt(moveData.rank, 10),
                note: moveData.note || '',
                winrate: parseFloat(moveData.winrate) || 0
            };
        });
    }

    hasValidMoves(parsedMoves) {
        return Array.isArray(parsedMoves) && parsedMoves.length > 0;
    }

    handleEmptyResponse(attempt, fen) {
        console.warn(`Attempt ${attempt}: Received empty or invalid response for FEN: "${fen}". Retrying...`);
    }

    handleFetchError(attempt, error) {
        console.error(`Attempt ${attempt}: Error fetching data:`, error);
    }

    finalFailureLog() {
        console.error('All fallback attempts failed. Unable to retrieve move data.');
    }

    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // Internal method to fetch moves from Lichess API
    async getLichessMovesInternal(fen, numberOfMoves) {
        const url = `${this.lichessApiUrl}?fen=${encodeURIComponent(fen)}&multiPv=${numberOfMoves}`; // Fetch top numberOfMoves variations
        try {
            const response = await this.fetchData(url);
            const lichessData = JSON.parse(response);
            return this.parseLichessResponse(lichessData, fen);
        } catch (error) {
            console.error('Failed to fetch data from Lichess API.', error.message);
            return [];
        }
    }

    parseLichessResponse(data, fen) {
        if (!data || !data.pvs || data.pvs.length === 0) {
            console.warn('No valid move data found in Lichess API response:', data);
            return [];
        }

        const isBlackTurn = fen.split(' ')[1] === 'b';

        return data.pvs.map((pv, index) => {
            const originalMove = pv.moves.split(' ')[0];
            const correctedMove = this.correctCastlingMove(originalMove, fen);
            const score = isBlackTurn ? -pv.cp : pv.cp; // Invert score for Black's turn
            return {
                move: correctedMove,
                score: score, // Score in centipawns
                rank: index + 1, // Rank is the index of the variation
                note: `Depth: ${data.depth}, Nodes: ${data.knodes}`, // Adding depth and nodes info to note
                winrate: 0 // Lichess does not provide winrate, so we default to 0
            };
        });
    }

    // Correct castling moves by parsing the FEN string
    correctCastlingMove(move, fen) {
        const [board, activeColor, castlingAvailability] = fen.split(' ');

        const from = move.slice(0, 2);
        const to = move.slice(2, 4);

        const kingSquare = activeColor === 'w' ? 'e1' : 'e8';
        const kingsideRookSquare = activeColor === 'w' ? 'h1' : 'h8';
        const queensideRookSquare = activeColor === 'w' ? 'a1' : 'a8';

        // Check if the move is from the king's original square
        if (from !== kingSquare) {
            // Not a castling move
            return move;
        }

        // Determine if castling is possible from the castling availability field
        const canCastleKingside = castlingAvailability.includes(activeColor === 'w' ? 'K' : 'k');
        const canCastleQueenside = castlingAvailability.includes(activeColor === 'w' ? 'Q' : 'q');

        // Correct the move if it matches the rook square and castling is available
        if (to === kingsideRookSquare && canCastleKingside) {
            // Kingside castling
            const correctedTo = activeColor === 'w' ? 'g1' : 'g8';
            return from + correctedTo;
        } else if (to === queensideRookSquare && canCastleQueenside) {
            // Queenside castling
            const correctedTo = activeColor === 'w' ? 'c1' : 'c8';
            return from + correctedTo;
        }

        // Return the original move if no correction is needed
        return move;
    }
}

export default ChessDB;
