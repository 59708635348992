// components/PromotionModal.js
import React from 'react';
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import SvgPiece from './SvgPiece';

const PromotionModal = ({ isVisible, onSelect, color }) => {
  const pieces = ['q', 'r', 'b', 'n'];

  return (
    <Modal transparent={true} animationType="fade" visible={isVisible}>
      <View style={styles.modalBackground}>
        <View style={styles.modalContainer}>
          <Text style={styles.title}>Choose Promotion</Text>
          <View style={styles.optionsContainer}>
            {pieces.map((p) => (
              <TouchableOpacity key={p} onPress={() => onSelect(p)}>
                <SvgPiece
                  piece={{ type: p, color: color }}
                  size={50}
                />
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  optionsContainer: {
    flexDirection: 'row',
  },
});

export default PromotionModal;
