// components/SideMenu.js

import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import BaseSideMenu from './BaseSideMenu';

const SideMenu = ({
  isVisible,
  onClose,
  onTrainPosition,
  selectedElo,
  onEloChange,
  onRestart,
  playerColor, // Received current player color
  onPlayOppositeColor, // Handler for playing opposite color
}) => {
  const eloOptions = [1200, 1400, 1600, 1800, 2200, 2500];

  return (
    <BaseSideMenu
      isVisible={isVisible}
      onClose={onClose}
      position="right"
      width={200}
    >
      <TouchableOpacity style={styles.menuItem} onPress={onRestart}>
        <Text style={styles.menuItemText}>Restart Training</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.menuItem} onPress={onTrainPosition}>
        <Text style={styles.menuItemText}>Train this position</Text>
      </TouchableOpacity>

      {/* New Button for Playing Opposite Color */}
      <TouchableOpacity style={styles.menuItem} onPress={onPlayOppositeColor}>
        <Text style={styles.menuItemText}>
          Play this position as {playerColor === 'w' ? 'Black' : 'White'}
        </Text>
      </TouchableOpacity>

      <Text style={styles.menuHeaderText}>Select ELO Rating</Text>
      <View style={styles.eloOptionsContainer}>
        {eloOptions.map((elo) => (
          <TouchableOpacity
            key={elo}
            style={[
              styles.eloOption,
              selectedElo === elo && styles.selectedEloOption,
            ]}
            onPress={() => onEloChange(elo)}
          >
            <Text
              style={[
                styles.eloOptionText,
                selectedElo === elo && styles.selectedEloOptionText,
              ]}
            >
              {elo}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </BaseSideMenu>
  );
};

const styles = StyleSheet.create({
  menuItem: {
    padding: 15,
    borderRadius: 8,
    backgroundColor: '#2563eb',
    marginBottom: 10,
  },
  menuItemText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  },
  menuHeaderText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 10,
  },
  eloOptionsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  eloOption: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: '#374151',
    marginBottom: 10,
    width: '30%',
    alignItems: 'center',
  },
  selectedEloOption: {
    backgroundColor: '#2563eb',
  },
  eloOptionText: {
    color: '#9ca3af',
    fontSize: 14,
  },
  selectedEloOptionText: {
    color: '#fff',
    fontWeight: 'bold',
  },
});

export default SideMenu;
