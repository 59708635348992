// components/CompletionModal.js

import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Modal } from 'react-native';

const CompletionModal = ({
  visible,
  onClose,
  onRestart,
  onSelectOpening,
  metrics,
}) => {
  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          {/* Close Button */}
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <Text style={styles.closeButtonText}>×</Text>
          </TouchableOpacity>

          {/* Congratulations Text */}
          <Text style={styles.modalTitle}>Congratulations!</Text>

          {/* Scoring Metrics */}
          <ScrollView style={styles.metricsContainer}>
            <Text style={styles.metricsTitle}>Scoring Metrics</Text>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Book Moves:</Text>
              <Text style={styles.metricValue}>{metrics.bookMovesCount}</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Wrong Book Moves:</Text>
              <Text style={styles.metricValue}>{metrics.nonBookMovesCount}</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Best Moves:</Text>
              <Text style={styles.metricValue}>{metrics.bestMovesCount}</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Great Moves:</Text>
              <Text style={styles.metricValue}>{metrics.greatMovesCount}</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Mistakes:</Text>
              <Text style={styles.metricValue}>{metrics.mistakesCount}</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Hints Used:</Text>
              <Text style={styles.metricValue}>{metrics.hintsCount}</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Total Centipawn Loss:</Text>
              <Text style={styles.metricValue}>{metrics.totalCentipawnLoss} cp</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricLabel}>Final Engine Evaluation:</Text>
              <Text style={styles.metricValue}>
                {metrics.finalEngineEvaluation !== null ? `${metrics.finalEngineEvaluation} cp` : 'N/A'}
              </Text>
            </View>
          </ScrollView>

          {/* Buttons */}
          <View style={styles.modalButtons}>
            <TouchableOpacity style={styles.modalButton} onPress={onRestart}>
              <Text style={styles.modalButtonText}>Restart</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.modalButton} onPress={onSelectOpening}>
              <Text style={styles.modalButtonText}>Select Opening</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    width: 300,
    maxHeight: '80%',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    position: 'relative',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
    textAlign: 'center',
  },
  metricsContainer: {
    width: '100%',
    marginBottom: 20,
  },
  metricsTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    textAlign: 'center',
  },
  metricItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
  },
  metricLabel: {
    fontSize: 16,
    color: '#333',
  },
  metricValue: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
  },
  modalButtons: {
    flexDirection: 'row',
    marginTop: 10,
  },
  modalButton: {
    padding: 10,
    marginHorizontal: 10,
    backgroundColor: '#2563eb',
    borderRadius: 5,
    alignItems: 'center',
    minWidth: 120,
  },
  modalButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 5,
  },
  closeButtonText: {
    fontSize: 20,
    fontWeight: '700',
  },
});

export default CompletionModal;
