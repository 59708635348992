// components/Trainer.js

import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
  Platform,
} from 'react-native';
import useTrainerLogic from './TrainerLogic';
import Board from './Board';
import MoveHistory from './MoveHistory';
import CompletionModal from './CompletionModal';
import { Chess } from 'chess.js';
import MenuButton from './MenuButton';
import SideMenu from './SideMenu';

const Trainer = ({
  pgn,
  playerColor = 'w',
  onBack,
  openingName,
  appHeaderHeight,
}) => {
  const [isCompletionModalVisible, setCompletionModalVisible] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [boardWidth, setBoardWidth] = useState(0);
  const [currentPgn, setCurrentPgn] = useState(pgn);
  const [currentPlayerColor, setCurrentPlayerColor] = useState(playerColor);
  const [shouldHandlePlayOppositeColor, setShouldHandlePlayOppositeColor] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [selectedElo, setSelectedElo] = useState(() => {
    const savedElo = localStorage.getItem('selectedElo');
    return savedElo ? parseInt(savedElo) : 1600;
  });

  const {
    game,
    mistakeSquare,
    hintSquares,
    handleMove,
    showHint,
    resetGame,
    loadPgn,
    bookMovesCount,
    nonBookMovesCount,
    bestMovesCount,
    greatMovesCount,
    mistakesCount,
    hintsCount,
    totalCentipawnLoss,
    finalEngineEvaluation,
  } = useTrainerLogic(currentPgn, currentPlayerColor, selectedElo, (isCompleted) => {
    if (isCompleted) {
      setCompletionModalVisible(true);
    }
  });

  const [displayedGame, setDisplayedGame] = useState(new Chess(game.fen()));
  const [currentMoveIndex, setCurrentMoveIndex] = useState(
    game.history().length
  );

  useEffect(() => {
    setCurrentMoveIndex(game.history().length);
  }, [game.history().length]);

  useEffect(() => {
    const newGame = new Chess();
    const history = game.history({ verbose: true });
    for (let i = 0; i < currentMoveIndex; i++) {
      newGame.move(history[i]);
    }
    setDisplayedGame(newGame);
  }, [currentMoveIndex, game]);

  useEffect(() => {
    if (shouldHandlePlayOppositeColor) {
      const moveIndex = displayedGame.history().length;
      const newPgn = displayedGame.pgn();
      loadPgn(newPgn, moveIndex);
      setShouldHandlePlayOppositeColor(false); // Reset the flag
    }
  }, [shouldHandlePlayOppositeColor]); // Dependency array includes only shouldReloadPgn

  const { width, height } = useWindowDimensions();
  const availableHeight =
    height - headerHeight - footerHeight - appHeaderHeight;

  const handleRestart = () => {
    resetGame(true);
    setCompletionModalVisible(false);
    setCurrentMoveIndex(0);
  };

  const handleSelectOpening = () => {
    setCompletionModalVisible(false);
    onBack();
  };

  const handleCloseModal = () => {
    setCompletionModalVisible(false);
  };

  const onBoardMove = async (move, promotion) => {
    const success = await handleMove(move, promotion);
    if (success !== false) {
      setCurrentMoveIndex(game.history().length);
    }
    return { MoveHasBeenHandled: true, success: success };
  };

  const metrics = {
    bookMovesCount,
    nonBookMovesCount,
    bestMovesCount,
    greatMovesCount,
    mistakesCount,
    hintsCount,
    totalCentipawnLoss,
    finalEngineEvaluation,
  };

  const goToMove = (moveIndex) => {
    setCurrentMoveIndex(moveIndex + 1);
  };

  const handleBack = () => {
    setCurrentMoveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleForward = () => {
    setCurrentMoveIndex((prevIndex) =>
      Math.min(prevIndex + 1, game.history().length)
    );
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
          handleBack();
        } else if (event.key === 'ArrowRight') {
          handleForward();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [currentMoveIndex, game]);

  const handleTrainThisPosition = () => {
    const newPgn = displayedGame.pgn();
    loadPgn(newPgn);
  };

  const handleOpenMenu = () => {
    setMenuVisible(true);
  };

  const handleCloseMenu = () => {
    setMenuVisible(false);
  };

  const handleTrainPositionFromMenu = () => {
    handleTrainThisPosition();
    handleCloseMenu();
  };

  const handleRestartFromMenu = () => {
    handleRestart();
    handleCloseMenu();
  };
    // Handler for playing the position as the opposite color
    const handlePlayOppositeColor = () => {
      const newColor = currentPlayerColor === 'w' ? 'b' : 'w';
      setCurrentPlayerColor(newColor);    
      setShouldHandlePlayOppositeColor(true);
    
      handleCloseMenu();
    };
  return (
    <View style={styles.container}>
      <MenuButton
        onPress={isMenuVisible ? handleCloseMenu : handleOpenMenu}
        style={styles.menuButton}
      />
      <SideMenu
        isVisible={isMenuVisible}
        onClose={handleCloseMenu}
        onTrainPosition={handleTrainPositionFromMenu}
        selectedElo={selectedElo}
        onEloChange={(elo) => {
          setSelectedElo(elo);
          localStorage.setItem('selectedElo', elo);
        }}
        onRestart={handleRestartFromMenu}
        playerColor={currentPlayerColor} // Passed current player color
        onPlayOppositeColor={handlePlayOppositeColor} // Passed handler
      />
      {/* Board Container */}
      <View
        style={[
          styles.boardContainer,
          {
            maxHeight: availableHeight,
            maxWidth: Math.min(width, availableHeight),
          },
        ]}
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setBoardWidth(width);
        }}
      >
        <Board
          game={displayedGame}
          onMove={onBoardMove}
          playerColor={currentPlayerColor} // Updated to use currentPlayerColor
          mistakeSquare={mistakeSquare}
          hintSquares={hintSquares}
          isInteractive={currentMoveIndex === game.history().length}
        />
      </View>

      {/* Footer Container */}
      <View
        style={styles.footerContainer}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setFooterHeight(height);
        }}
      >
        <View
          style={[
            styles.moveHistoryContainer,
            { width: boardWidth, alignSelf: 'center' },
          ]}
        >
          <MoveHistory
            history={game.history()}
            onSelectMove={goToMove}
            currentMoveIndex={currentMoveIndex}
            onBack={handleBack}
            onForward={handleForward}
          />
        </View>
        <View style={[styles.buttonContainer]}>
          <TouchableOpacity style={styles.hintButton} onPress={showHint}>
            <Text style={styles.hintButtonText}>Hint</Text>
          </TouchableOpacity>
        </View>
      </View>
      {/* Completion Modal */}
      <CompletionModal
        visible={isCompletionModalVisible}
        onClose={handleCloseModal}
        onRestart={handleRestart}
        onSelectOpening={handleSelectOpening}
        metrics={metrics}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1f2937',
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
  },
  boardContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 0,
  },
  footerContainer: {
    paddingVertical: 0,
    backgroundColor: '#1f2937',
    width: '100%',
    alignItems: 'center',
  },
  moveHistoryContainer: {
    marginBottom: 0,
  },
  buttonContainer: {
    flexDirection: 'column',
    width: '100%',
    paddingHorizontal: 20,
    marginTop: 2,
    marginBottom: 2,
  },
  hintButton: {
    padding: 5,
    height: 30,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: '#2563eb',
    borderRadius: 5,
    alignItems: 'center',
    alignSelf: 'center',
    width: 100,
  },
  hintButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  trainButton: {
    padding: 5,
    backgroundColor: '#2563eb',
    borderRadius: 5,
    alignItems: 'center',
    width: 150,
    alignSelf: 'flex-end',
  },
  trainButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  menuButton: {
    position: 'absolute',
    bottom: 2,
    right: 5,
    zIndex: 2000,
    height: 30,
    width: 30,
  },
});

export default Trainer;
