import React, { useState } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import ChessOpeningGrid from './components/OpeningSelect'; // Import your opening selector component
import Trainer from './components/Trainer';
import OpeningExplorer from './components/OpeningExplorer';
import Sidebar from './components/Sidebar'; // Import the updated Sidebar component
import PGNInputModal from './components/PGNInputModal'; // Import the PGNInputModal component
import MenuButton from './components/MenuButton'; // Import the MenuButton component

const App = () => {
  const [selectedOpening, setSelectedOpening] = useState(null);
  const [exploringOpening, setExploringOpening] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isPGNModalVisible, setPGNModalVisible] = useState(false);
  const [appHeaderHeight, setAppHeaderHeight] = useState(0);  

  const handleOpeningSelect = (opening) => {
    setSelectedOpening(opening);
    setSidebarVisible(false); // Close sidebar if open
  };

  const handleExploreOpening = (opening) => {
    setExploringOpening(opening);
    setSelectedOpening(null);
  }

  const handleBack = () => {
    setSelectedOpening(null);
    setExploringOpening(null);
  };

  const playerColor = selectedOpening
    ? selectedOpening.color === 'white'
      ? 'w'
      : 'b'
    : 'w';

  // Handler for "Open PGN"
  const handleOpenPGN = () => {
    setPGNModalVisible(true);
  };

  const handleSelectOpening = () => {
    setSelectedOpening(null); // Display the ChessOpeningGrid
  };

  const handleSubmitPGN = ({ pgn, color }) => {
    const opening = {
      name: 'Custom PGN',
      pgn: pgn,
      color: color,
    };
    setSelectedOpening(opening);
    setPGNModalVisible(false);
  };

  return (
    <SafeAreaView style={styles.container}>
      {/* Sidebar Button */}
      <MenuButton
        onPress={() => setSidebarVisible(!isSidebarVisible)} // Toggle visibility
        style={styles.sidebarButton}
      />      
      {/* Header */}
      <View 
        style={styles.header}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setAppHeaderHeight(height);
        }}
      >
        {/* Title */}
        <Text style={styles.title}>
          {selectedOpening ? selectedOpening.name : 'Chess Upgrader'}
        </Text>
      </View>

      {/* Content */}
      {selectedOpening ? (
        <Trainer
          pgn={selectedOpening.pgn}
          playerColor={playerColor}
          onBack={handleBack}
          openingName={selectedOpening.name}
          appHeaderHeight={appHeaderHeight}
        />
      ) : exploringOpening ? (
        <OpeningExplorer
          opening={exploringOpening}
          onBack={handleBack}
        />
      ) : (
        <ChessOpeningGrid
          onSelectOpening={handleOpeningSelect}
          onExploreOpening={handleExploreOpening}
        />
      )}

      {/* Sidebar Menu */}
      <Sidebar
        isVisible={isSidebarVisible}
        onClose={() => setSidebarVisible(false)}
        onSelectOpening={handleSelectOpening}
        onOpenPGN={handleOpenPGN}
      />

      {/* PGN Input Modal */}
      <PGNInputModal
        visible={isPGNModalVisible}
        onClose={() => setPGNModalVisible(false)}
        onSubmit={handleSubmitPGN}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#111827', // Dark background for dark mode
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    height: 40,
    backgroundColor: '#1f2937', // Slightly lighter dark background for header
  },
  sidebarButton: {
    position: 'absolute',
    left: 5,
    height: 32,
    width: 32,
    top: 2,
    zIndex: 2000,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff', // White text for visibility
    textAlign: 'center',
    flex: 1,
  },
});

export default App;
