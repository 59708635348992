// components/Board.js

import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Alert,
  Animated,
  Platform,
} from 'react-native';
import { Chess } from 'chess.js';
import PromotionModal from './PromotionModal';
import SvgPiece from './SvgPiece';
import PropTypes from 'prop-types';

// Conditional import for react-native-sound
let Sound;
if (Platform.OS !== 'web') {
  try {
    Sound = require('react-native-sound').default;
    Sound.setCategory('Playback');
  } catch (e) {
    console.warn('Failed to load react-native-sound', e);
  }
}

// Import the move sound
const moveSoundSrc = require('../assets/move.mp3'); // Ensure the path is correct

const Board = ({
  game: initialGame,
  onMove,
  playerColor,
  mistakeSquare,
  hintSquares,
  isInteractive = true,
}) => {
  const [game, setGame] = useState(initialGame || new Chess());
  const [board, setBoard] = useState([]);
  const [selectedSquare, setSelectedSquare] = useState(null);
  const [isPromotionVisible, setPromotionVisible] = useState(false);
  const [promotionOptions, setPromotionOptions] = useState({});
  const [mistakeOpacity] = useState(new Animated.Value(0));
  const [hintOpacity] = useState(new Animated.Value(0));
  const [lastMove, setLastMove] = useState(null);

  // Refs for sound instances
  const webAudioPoolRef = useRef({ audioContext: null, buffer: null });
  const mobileSoundRef = useRef(null);

  // Define the starting FEN for comparison
  const startingFEN = useRef(new Chess().fen()).current;

  // Ref to store the previous FEN
  const prevFENRef = useRef(game.fen());

  // Load the sound once when the component mounts
  useEffect(() => {
    if (Platform.OS === 'web') {
      // Web platform: Initialize Web Audio API
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      if (!AudioContext) {
        console.error('Web Audio API is not supported in this browser');
        return;
      }
      const audioContext = new AudioContext();
      webAudioPoolRef.current.audioContext = audioContext;

      fetch(moveSoundSrc)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          webAudioPoolRef.current.buffer = audioBuffer;
        })
        .catch((error) => {
          console.error('Error loading audio buffer:', error);
        });

      // Cleanup on unmount
      return () => {
        if (audioContext) {
          audioContext.close();
        }
      };
    } else if (Sound) {
      // Mobile platform: Initialize Sound instance
      mobileSoundRef.current = new Sound(moveSoundSrc, Sound.MAIN_BUNDLE, (error) => {
        if (error) {
          console.error('Failed to load the move sound', error);
        }
      });
    }

    // Cleanup on unmount
    return () => {
      if (mobileSoundRef.current) {
        mobileSoundRef.current.release();
      }
    };
  }, []);

  useEffect(() => {
    setGame(initialGame);
  }, [initialGame]);

  useEffect(() => {
    setBoard(game.board());
  }, [game.fen()]);

  // Handle mistake square animation
  useEffect(() => {
    if (mistakeSquare) {
      mistakeOpacity.setValue(1);
      Animated.timing(mistakeOpacity, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    }
  }, [mistakeSquare, mistakeOpacity]);

  // Handle hint squares animation
  useEffect(() => {
    if (hintSquares && hintSquares.length > 0) {
      hintOpacity.setValue(0);
      Animated.loop(
        Animated.sequence([
          Animated.timing(hintOpacity, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(hintOpacity, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }),
        ]),
        {
          iterations: 2,
        }
      ).start();
    }
  }, [hintSquares, hintOpacity]);

  // Update last move when game changes
  useEffect(() => {
    const history = game.history({ verbose: true });
    if (history.length > 0) {
      const recentMove = history[history.length - 1];
      setLastMove({ from: recentMove.from, to: recentMove.to });
    } else {
      setLastMove(null);
    }
  }, [game.fen()]);

  // Play move sound when the board changes due to a move
  useLayoutEffect(() => {
    // Safari requires you to use useLayoutEffect instead of useEffect to play the sound
    const currentFEN = game.fen();
    const previousFEN = prevFENRef.current;

    if (previousFEN !== currentFEN) {
      const isReset = currentFEN === startingFEN;

      if (!isReset) {
        // It's a move
        playMoveSound();
      }
    }

    // Update the previous FEN
    prevFENRef.current = currentFEN;
  }, [game.fen()]);

  const isSquarePartOfLastMove = (square, lastMove) => {
    if (!lastMove) return false;
    return square === lastMove.from || square === lastMove.to;
  };

  const getSquareNotation = (row, col) => {
    const files = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
    return files[col] + (8 - row);
  };

  const onSquarePress = (row, col) => {
    if (!isInteractive) return;

    const square = getSquareNotation(row, col);
    if (selectedSquare === square) return;

    const piece = game.get(square);

    if (piece && piece.color === playerColor && square !== selectedSquare) {
      setSelectedSquare(square);
      return;
    }

    if (selectedSquare) {
      const move = { from: selectedSquare, to: square };
      const possibleMoves = game.moves({ square: selectedSquare, verbose: true });
      const isValidMove = possibleMoves.some((m) => m.to === square);

      if (isValidMove) {
        const movingPiece = game.get(selectedSquare);
        if (
          movingPiece.type === 'p' &&
          (square.endsWith('1') || square.endsWith('8'))
        ) {
          setPromotionOptions({ ...move, color: movingPiece.color });
          setPromotionVisible(true);
        } else {
          makeMove(move);
        }
      }
    } else {
      if (piece && piece.color === game.turn()) {
        setSelectedSquare(square);
      }
    }
  };

  const makeMove = async (move, promotion) => {
    if (onMove) {
      const result = await onMove(move, promotion);

      if (result) {
        if (result.success && selectedSquare) {
          const piece = game.get(selectedSquare);
          if (piece && piece.color !== game.turn()) {
            // Allow a piece to be selected before the player's turn
            setSelectedSquare(null);
          }
        }
        if (result.MoveHasBeenHandled) {
          return;
        }
      }
    }

    const moveResult = game.move({
      from: move.from,
      to: move.to,
      promotion: promotion,
    });

    if (moveResult) {
      setBoard(game.board());
      setSelectedSquare(null);
      checkGameOver(game);
    }
  };

  const playMoveSound = () => {
    if (Platform.OS === 'web') {
      const { audioContext, buffer } = webAudioPoolRef.current;
      if (audioContext && buffer) {
        const playSound = () => {
          const source = audioContext.createBufferSource();
          source.buffer = buffer;
          source.connect(audioContext.destination);
          source.start(0);
        };

        if (audioContext.state === 'suspended') {
          audioContext.resume().then(playSound).catch((error) => {
            console.error('Error resuming audio context:', error);
          });
        } else {
          playSound();
        }
      }
    } else if (mobileSoundRef.current) {
      // Mobile platform: Play the preloaded Sound instance
      mobileSoundRef.current.stop(() => {
        mobileSoundRef.current.play((success) => {
          if (!success) {
            console.error('Sound playback failed');
          }
        });
      });
    }
  };

  const checkGameOver = (game) => {
    if (game.isGameOver()) {
      if (game.isCheckmate()) {
        Alert.alert(
          'Checkmate',
          `Player ${game.turn() === 'w' ? 'Black' : 'White'} wins!`
        );
      } else if (game.isStalemate()) {
        Alert.alert('Stalemate', 'Draw!');
      } else if (game.isDraw()) {
        Alert.alert('Draw', 'Draw!');
      }
    }
  };

  const renderSquare = (row, col) => {
    const actualRow = playerColor === 'b' ? 7 - row : row;
    const actualCol = playerColor === 'b' ? 7 - col : col;
    const isLightSquare = (actualRow + actualCol) % 2 === 0;

    const square = getSquareNotation(actualRow, actualCol);
    const piece = game.get(square);
    const isSelected = square === selectedSquare;
    const isMistake = square === mistakeSquare;
    const isHint = hintSquares.includes(square);

    const isLastMove = isSquarePartOfLastMove(square, lastMove);

    let squareColor;
    if (isLastMove) {
      squareColor = isLightSquare ? '#f7dc6f' : '#AAA23A';
    } else {
      squareColor = isLightSquare ? '#f0d9b5' : '#b58863';
    }

    return (
      <TouchableOpacity
        key={`${row}-${col}`}
        style={[styles.square, { backgroundColor: squareColor }]}
        onPress={() => onSquarePress(actualRow, actualCol)}
      >
        {piece && <SvgPiece piece={piece} style={styles.piece} />}
        {isSelected && <View style={styles.selectedOverlay} />}
        {isMistake && (
          <Animated.View
            style={[styles.mistakeOverlay, { opacity: mistakeOpacity }]}
          />
        )}
        {isHint && (
          <Animated.View
            style={[styles.hintOverlay, { opacity: hintOpacity }]}
          />
        )}
      </TouchableOpacity>
    );
  };

  const renderBoard = () => {
    return board.map((rowData, row) => (
      <View key={row} style={styles.row}>
        {rowData.map((colData, col) => renderSquare(row, col))}
      </View>
    ));
  };

  const onPromotionChoice = (promotion) => {
    setPromotionVisible(false);
    makeMove(promotionOptions, promotion);
  };

  return (
    <View style={styles.container}>
      <View style={styles.board}>{renderBoard()}</View>
      {isPromotionVisible && (
        <PromotionModal
          isVisible={isPromotionVisible}
          onSelect={onPromotionChoice}
          color={promotionOptions.color}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    aspectRatio: 1,
    alignSelf: 'center',
  },
  board: {
    flex: 1,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  square: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  piece: {
    width: '80%',
    height: '80%',
    resizeMode: 'contain',
  },
  selectedOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(100, 100, 100, 0.4)',
  },
  mistakeOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(200, 20, 20, 0.5)',
  },
  hintOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(20, 20, 200, 0.5)',
  },
});

Board.propTypes = {
  game: PropTypes.object.isRequired,
  onMove: PropTypes.func,
  playerColor: PropTypes.oneOf(['w', 'b']),
  mistakeSquare: PropTypes.string,
  hintSquares: PropTypes.arrayOf(PropTypes.string),
  isInteractive: PropTypes.bool,
};

Board.defaultProps = {
  playerColor: 'w',
  mistakeSquare: null,
  hintSquares: [],
  isInteractive: true,
};

export default Board;
