// PGNInputModal.js
import React, { useState } from 'react';
import {
  Modal,
  View,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Text,
  TouchableWithoutFeedback,
} from 'react-native';

const PGNInputModal = ({ visible, onClose, onSubmit }) => {
  const [pgnText, setPgnText] = useState('');
  const [selectedColor, setSelectedColor] = useState('white'); // Default to white

  const handleSubmit = () => {
    if (pgnText.trim() === '') {
      alert('Please enter a valid PGN.');
      return;
    }
    onSubmit({ pgn: pgnText, color: selectedColor });
    setPgnText('');
    setSelectedColor('white');
  };

  return (
    <Modal
      visible={visible}
      animationType="slide"
      onRequestClose={onClose}
      transparent={true}
    >
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.modalOverlay}>
          <TouchableWithoutFeedback onPress={() => {}}>
            <View style={styles.modalContent}>
              {/* Close Button */}
              <TouchableOpacity
                style={styles.closeButton}
                onPress={onClose}
                accessibilityLabel="Close"
                accessibilityHint="Closes the modal without selecting a PGN"
              >
                <Text style={styles.closeButtonText}>×</Text>
              </TouchableOpacity>

              <Text style={styles.heading}>Enter PGN</Text>

              <TextInput
                style={styles.textInput}
                placeholder="Paste your PGN here"
                placeholderTextColor="#9ca3af"
                value={pgnText}
                onChangeText={setPgnText}
                multiline
                accessibilityLabel="PGN Input"
                accessibilityHint="Enter the PGN notation of the game or opening you want to practice"
              />

              <Text style={styles.label}>Select your color:</Text>
              <View style={styles.segmentedControl}>
                <TouchableOpacity
                  style={[
                    styles.segmentButton,
                    selectedColor === 'white' ? styles.activeButton : styles.inactiveButton,
                  ]}
                  onPress={() => setSelectedColor('white')}
                  accessibilityLabel="Play as White"
                  accessibilityHint="Select to play as White"
                >
                  <Text
                    style={
                      selectedColor === 'white' ? styles.activeText : styles.whiteText
                    }
                  >
                    {selectedColor === 'white' ? '♔ White' : 'White'}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.segmentButton,
                    selectedColor === 'black' ? styles.activeButton : styles.inactiveButton,
                  ]}
                  onPress={() => setSelectedColor('black')}
                  accessibilityLabel="Play as Black"
                  accessibilityHint="Select to play as Black"
                >
                  <Text
                    style={
                      selectedColor === 'black' ? styles.activeText : styles.blackText
                    }
                  >
                    {selectedColor === 'black' ? '♚ Black' : 'Black'}
                  </Text>
                </TouchableOpacity>
              </View>

              {/* Centered Submit Button */}
              <View style={styles.submitButtonContainer}>
                <TouchableOpacity
                  style={styles.submitButton}
                  onPress={handleSubmit}
                  accessibilityLabel="Submit"
                  accessibilityHint="Starts training with the entered PGN and selected color"
                >
                  <Text style={styles.submitButtonText}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(17, 24, 39, 0.9)', // Semi-transparent dark background
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#1f2937',
    padding: 20,
    borderRadius: 10,
    width: '90%',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 5,
  },
  closeButtonText: {
    fontSize: 24,
    color: '#fff',
  },
  heading: {
    fontSize: 24,
    color: '#ffffff',
    marginBottom: 20,
    textAlign: 'center',
  },
  textInput: {
    height: 150,
    borderColor: '#374151',
    borderWidth: 1,
    color: '#ffffff',
    marginBottom: 20,
    padding: 10,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  label: {
    color: '#9ca3af',
    marginBottom: 10,
    fontSize: 16,
  },
  // Segmented control styles
  segmentedControl: {
    flexDirection: 'row',
    borderRadius: 8,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#ccc',
    width: 160,
    marginBottom: 20,
  },
  segmentButton: {
    flex: 1,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeButton: {
    backgroundColor: '#8B4513', // Darker wood color for the selected button
  },
  inactiveButton: {
    backgroundColor: '#D2B48C', // Lighter wooden background for unselected
  },
  activeText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  whiteText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  blackText: {
    color: '#000',
    fontWeight: 'bold',
  },
  // Centered Submit Button
  submitButtonContainer: {
    alignItems: 'center',
    marginTop: 10,
  },
  submitButton: {
    backgroundColor: '#10b981',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  submitButtonText: {
    color: '#fff',
    fontSize: 16,
  },
});

export default PGNInputModal;
