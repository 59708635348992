// components/SvgPiece.js
import React from 'react';
import { View, StyleSheet } from 'react-native';

// Import your .svg files as components
import { ReactComponent as wp } from '../assets/wp.svg';
import { ReactComponent as wr } from '../assets/wr.svg';
import { ReactComponent as wn } from '../assets/wn.svg';
import { ReactComponent as wb } from '../assets/wb.svg';
import { ReactComponent as wq } from '../assets/wq.svg';
import { ReactComponent as wk } from '../assets/wk.svg';
import { ReactComponent as bp } from '../assets/bp.svg';
import { ReactComponent as br } from '../assets/br.svg';
import { ReactComponent as bn } from '../assets/bn.svg';
import { ReactComponent as bb } from '../assets/bb.svg';
import { ReactComponent as bq } from '../assets/bq.svg';
import { ReactComponent as bk } from '../assets/bk.svg';

// Create a mapping from piece names to components
const pieceComponents = {
  wp: wp,
  wr: wr,
  wn: wn,
  wb: wb,
  wq: wq,
  wk: wk,
  bp: bp,
  br: br,
  bn: bn,
  bb: bb,
  bq: bq,
  bk: bk,
};

const SvgPiece = ({ piece, size }) => {
  const pieceColor = piece.color === 'w' ? 'w' : 'b';
  const pieceType = piece.type; // 'p', 'r', 'n', 'b', 'q', or 'k'
  const imageName = `${pieceColor}${pieceType}`; // e.g., 'wp', 'bk'

  const PieceComponent = pieceComponents[imageName];

  if (!PieceComponent) {
    return null;
  }

  return (
    <View style={[styles.container, { width: size, height: size }]}>
      <PieceComponent style={styles.piece}  />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  piece: {
    width: '100%',
    height: '100%',
  },
});

export default SvgPiece;
